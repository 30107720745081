import '@brightspace-ui/labs/components/attribute-picker.js';
import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';

import { css, html, LitElement } from 'lit';
import { isEmail } from '../../../../../../shared/helpers/util.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

class EmailListInput extends LocalizeNova(FormElementMixin(LitElement)) {

  static get properties() {
    return {
      label: { type: String },
      required: { type: Boolean },
      emails: { type: Array },
    };
  }

  static get styles() {
    return [
      inputLabelStyles,
      css`
        :host {
          display: block;
        }
`,
    ];
  }

  constructor() {
    super();
    this.emails = [];
    this._blurReplaced = false;
    this.setValidity({ badInput: true });
  }

  updated(_changedProperties) {
    if (_changedProperties.has('emails')) {
      this.setValidity({ badInput: this.emails.length <= 0 });
    }
    // The attribute picker component does not fire a blur event when the user tabs out of the input field. This overrides
    // the blur event handler directly on the component.
    if (this.attributePickerElement && !this._blurReplaced) {
      this.attributePickerElement._onInputBlur = () => {
        this.attributePickerElement._inputFocused = false;
        this._keyDownHandler({ key: 'Tab' });
      };
      this._blurReplaced = true;
    }
  }

  get attributePickerElement() {
    return this.shadowRoot.getElementById('attribute-picker');
  }

  get validationMessage() {
    if (this.validity.patternMismatch) {
      return this.localize('suggest-panel.form.error.invalid-email');
    } else if (this.validity.badInput) {
      return this.localize('suggest-panel.form.error.required');
    }
    return super.validationMessage;
  }

  _suggestListChanged(e) {
    const { attributeList } = e.detail;
    if (attributeList.length > this.emails.length) {
      const { value } = attributeList[attributeList.length - 1];
      if (isEmail(value)) {
        this.emails.push(value);
        this.setValidity({ patternMismatch: false });
      } else {
        this.attributePickerElement._text = value;
        this.attributePickerElement.attributeList.splice(this.attributePickerElement.attributeList.length - 1, 1);
        this.setValidity({ patternMismatch: true });
      }
    }
    this.emails = this.attributePickerElement.attributeList.map(attribute => attribute.value);

    this.setValidity({ badInput: this.emails.length <= 0 });
    this._dispatchEmailsChangedEvent();
  }

  /**
   * Ideally we would handle this with a keyup event, but the key-up event is not fired on this component when the Tab key is pressed.
   *
   * @param e
   * @private
   */
  _keyDownHandler(e) {
    const email = this.attributePickerElement._text.trim();

    if (e.key === 'Tab' && isEmail(email)) {
      this.attributePickerElement._text = '';
      this.emails = [...this.emails, email];
      this._dispatchEmailsChangedEvent();
      this.setValidity({ badInput: this.emails.length <= 0 });
    }
  }

  _keyupHandler() {
    const text = this.attributePickerElement._text;
    const textContainsSpace = text.includes(' ');
    const textContainsComma = text.includes(',');
    const textContainsSemicolon = text.includes(';');

    const invalidEmails = [];
    if (textContainsSpace || textContainsComma || textContainsSemicolon) {
      const emails = text.split(/[,;\s]+/);
      for (const email of emails) {
        if (isEmail(email)) {
          this.attributePickerElement._text = '';
          this.emails = [...this.emails, email];
          this._dispatchEmailsChangedEvent();
        } else if (email) {
          invalidEmails.push(email);
        }
      }
      this.attributePickerElement._text = invalidEmails.join(textContainsComma ? ',' : ';');

      this.setValidity({ badInput: this.emails.length <= 0 });
    }
  }

  /**
   * Dispatches a custom event to notify the parent that the emails have changed.
   * @private
   */
  _dispatchEmailsChangedEvent() {
    this.dispatchEvent(new CustomEvent('emails-changed', {
      bubbles: true,
      composed: true,
      detail: {
        emails: this.emails,
      },
    }));
  }

  render() {
    return html`
      <label class="suggest-form-label d2l-input-label" for="attribute-picker">${this.label}</label>
      <d2l-labs-attribute-picker
        @keydown="${this._keyDownHandler}"
        @keyup="${this._keyupHandler}"
        id="attribute-picker"
        @d2l-labs-attribute-picker-attributes-changed="${this._suggestListChanged}"
        class="suggest-form-input"
        aria-label="${this.label}"
        .attributeList=${this.emails.map(e => ({ name: e, value: e }))}
        allow-freeform>
      </d2l-labs-attribute-picker>
    `;
  }

}

window.customElements.define('email-list-input', EmailListInput);
