import '@brightspace-ui/core/components/empty-state/empty-state-action-button.js';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';

import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { RtlMixin } from '@brightspace-ui/core/mixins/rtl-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import ActivityFilter from '../../../../../app/shared/models/activity-filter/activity-filter.js';
import { LANDING_STREAM_TYPES } from '../../../../../shared/constants.js';
import LandingStream from '../../../../shared/models/landing-stream/landing-stream.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../../shared/components/general/nova-carousel/nova-carousel.js';

class SkillSetsCarousel extends SkeletonMixin(RtlMixin(RequesterMixin(nav(LocalizeNova(LitElement))))) {

  static get properties() {
    return {
      allCareerTitles: { type: Array }, // user current title and the selected career titles
      selectedSkillCategories: { type: Array }, // the selected skill sets
      skills: { type: Array }, // related skills from the selected skill sets
      careerTitle: { type: Object }, // the selected career title
      activityBySkillsSubcategories: { type: Object }, // the activities by deduped skill subcategories pass from parent
      heading: { type: String },
      empty: { type: Boolean, reflect: true }, // Reflected when the carousel is empty
      _data: { type: Object },
      _streams: { type: Array },
      _filter: { type: Object },
      _skillProfileSubCategories: { type: Array }, // all subcategories to be rendered in this carousel
      _activityCountsBySkillSet: { type: Object },
      _activityIdsToExclude: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([empty]) {
          display: none;
        }
        .empty-state-container {
          border: 2px solid #e6eaf0;
          border-radius: 12px;
          box-shadow: 2px 2px 10px 2px #0000000d;
          box-sizing: border-box;
          display: block;
          padding: 30px 60px;
          position: relative;
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.selectedSkillCategories = [];
    this.skills = null;
    this.careerTitle = null;
    this.activityBySkillsSubcategories = null;
    this.heading = '';
    this.skeleton = true;
    this.empty = false;
    this._data = null;
    this._skillProfileSubCategories = [];
    this._filter = null;
    this._streams = [];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._setupIntersectionObserver();
  }

  async disconnectedCallback() {
    super.disconnectedCallback();
    if (this._observer) {
      this._observer.disconnect();
    }
  }

  async updated(changedProperties) {
    super.updated();
    if (changedProperties.has('activityBySkillsSubcategories')) {
      console.log(this.activityBySkillsSubcategories);
    }
    if (!this.skeleton && (changedProperties.has('selectedSkillCategories') || changedProperties.has('skills') || changedProperties.has('careerTitle') || changedProperties.has('allCareerTitles'))) {
      await this._setupComponent();
    }
    this.empty = !this.session?.user.getSetting('selectedTitleId') || (!this.careerTitle && this.selectedSkillCategories.length === 0);
  }

  render() {
    if (this.empty) return nothing;
    return html`
      ${this._carouselTemplate}
    `;
  }

  get _carouselTemplate() {
    if (this.skeleton) {
      return html`
        <nova-carousel
          card-type="activity-card-wide"
          heading=${this.localize('general.loading')}
          max-cards-per-slide="3"
          skeleton>
        </nova-carousel>
      `;
    }

    if (!this._data !== null) {
      if (this._areAllStreamsRejected(this._streams)) {
        return this._emptyStateTemplate;
      }
      if (this._isFetchedDataEmpty(this._data)) {
        return nothing;
      }
    }

    let stylizedSkillCategories = this._skillProfileSubCategories.map(skillCategory => ({
      id: skillCategory.skillCategoryId,
      name: `${skillCategory.skillCategoryName}`,
      count: this._activityCountsBySkillSet.get(skillCategory.skillCategoryId),
    })).sort((a, b) => b.count - a.count);

    // TODO: Sort alphabetically and check on the new property from parent to filter out any skillSubcategories that has no activities
    // let stylizedSkillCategories = this._skillProfileSubCategories.map(skillCategory => ({
    //   id: skillCategory.skillCategoryId,
    //   name: `${skillCategory.skillCategoryName}`,
    //   value: this._activityData?.get(skillCategory.skillCategoryId)?.value,
    // })).sort((a, b) => b.value.count - a.value.count);

    stylizedSkillCategories = stylizedSkillCategories.filter(skillCategory => skillCategory.count && skillCategory.count > 0);

    const { bestResults, microlearning, courses, shortCredentials, degrees } = this._data;
    const tabsContent = [
      { tabTitle: this.localize('view-landing-page.carousel.tabTitle.bestResults'), content: bestResults.activities, totalActivitiesInList: bestResults.totalNumberOfHits, path: bestResults.path },
      { tabTitle: this.localize('view-landing-page.carousel.tabTitle.microlearning'), content: microlearning.activities, totalActivitiesInList: microlearning.totalNumberOfHits, path: microlearning.path },
      { tabTitle: this.localize('view-landing-page.carousel.tabTitle.courses'), content: courses.activities, totalActivitiesInList: courses.totalNumberOfHits, path: courses.path },
      { tabTitle: this.localize('view-landing-page.carousel.tabTitle.shortCredentials'), content: shortCredentials.activities, totalActivitiesInList: shortCredentials.totalNumberOfHits, path: shortCredentials.path },
      { tabTitle: this.localize('view-landing-page.carousel.tabTitle.degrees'), content: degrees.activities, totalActivitiesInList: degrees.totalNumberOfHits, path: degrees.path },
    ];

    const headingMenu = {
      id: 'skill-sets',
      displayText: !this.careerTitle ? undefined : this.careerTitle.jobName,
      defaultItem: !this.careerTitle ? stylizedSkillCategories[0] : undefined,
      label: this.localize('view-landing-page.carousel.interestedSkillSet.menuLabel'),
      items: stylizedSkillCategories,
    };

    // TODO:
    // - sort the activities by relevance/hype (https://desire2learn.atlassian.net/browse/NOVA-685)
    return html`
      <nova-carousel
        card-type="activity-card-wide"
        ?skeleton=${this.skeleton}
        heading=${this.heading}
        max-cards-per-slide="3"
        @menu-changed=${this._handleMenuChange}
        @view-all-clicked=${this._viewAllClicked}
        .tabsContent=${tabsContent}
        .headingMenu=${headingMenu}
      >
      </nova-carousel>
    `;
  }

  get _emptyStateTemplate() {
    return html`
      <div class="empty-state-container">
        <d2l-empty-state-illustrated description=${this.localize('view-landing-page.carousel.error.description')} title-text=${this.localize('general.error')}>
          <img aria-hidden="true" src="/assets/img/error-state-search.svg" slot="illustration">
          <d2l-empty-state-action-button
            @d2l-empty-state-action=${this._handleEmptyState}
            text=${this.localize('view-landing-page.carousel.error.action')}
          ></d2l-empty-state-action-button>
        </d2l-empty-state-illustrated>
      </div>
    `;
  }

  async _setupIntersectionObserver() {
    this._observer = new IntersectionObserver(async entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this._setupComponent();
          this._observer.disconnect();
        }
      });
    }, { threshold: 0.1 });

    this._observer.observe(this);
  }

  async _setupComponent() {
    this.skeleton = true;
    await this._setupSkillSets();
    await this._setupActivityCountBySkills();
    await this._setupData();
    this.skeleton = false;
  }

  _areAllStreamsRejected(streams) {
    return streams.every(stream => stream.streamStatus === 'rejected');
  }

  _getStreamData(stream) {
    const { streamStatus } = stream;

    if (streamStatus === 'rejected') {
      console.error('Fetching activities data failed');
      return { activities: [], totalNumberOfHits: 0, path: '' };
    }

    const { results, path } = stream.value;
    const { hits, total } = results;

    const activities = this._getActivitiesFromHits(hits);
    let totalNumberOfHits = total?.value ?? 0;

    if (stream.value?.id?.includes('best-results')) {
      return { activities: activities.slice(0, 6), totalNumberOfHits, path };
    } else if (this._activityIdsToExclude?.size) {
      const filteredActivities = activities.filter(activity => !this._activityIdsToExclude.has(activity.id));
      const numberOfActivitiesExcluded = (activities.length - filteredActivities.length);
      totalNumberOfHits -= numberOfActivitiesExcluded;
      return { activities: filteredActivities, totalNumberOfHits, path };
    } else {
      return { activities, totalNumberOfHits, path };
    }
  }

  _getActivitiesFromHits(hits) {
    return hits.map(act => {
      const activity = new Activity(act);
      return activity;
    });
  }

  _getSkillsForSkillCategory(skillCategoryId) {
    if (this.skills === null) return [];
    const matchingSkills = this.skills.filter(skill => skillCategoryId === skill?.skill?.subcategory.id);
    return matchingSkills?.map(matchingSkill => matchingSkill.skill);
  }

  _getStreamProps(streamData, results = undefined) {
    return {
      displayName: streamData.displayName,
      subtitle: streamData.subtitle,
      id: streamData.id,
      path: streamData.path,
      type: streamData.type,
      sort: streamData.sort,
      property: streamData.property,
      filters: streamData.filters,
      careerData: streamData.careerData,
      results: results,
    };
  }

  _isFetchedDataEmpty(data) {
    return Object.values(data).every(tabContent => {
      if (typeof tabContent === 'object') {
        return tabContent.totalNumberOfHits === 0;
      }
    });
  }

  async backoff(attempts) {
    const wait = Math.round(2000 + (1000 * (attempts - 1)));
    await new Promise(resolve => setTimeout(resolve, wait));
  }

  async _callOpenSearch(stream, opts = {}) {
    const MAX_TRIES = 3;
    const requestFn = async() => {
      const results = await this.client.searchActivities({
        ...opts,
      });
      if (results.hits !== undefined) {
        return this._getStreamProps(stream, results);
      } else {
        throw new Error(`Search activities failed with error: ${results}`);
      }
    };

    return await this._callOSWithRetries(requestFn, MAX_TRIES);
  }

  async _callOSWithRetries(requestFn, MAX_TRIES, tries = 0) {
    if (tries >= MAX_TRIES) {
      throw new Error('Maximum retries exceeded');
    }

    try {
      const value = await requestFn();
      return value;
    } catch (error) {
      tries++;
      await this.backoff(tries);
      return await this._callOSWithRetries(requestFn, MAX_TRIES, tries);
    }
  }

  _handleEmptyState() {
    location.reload();
  }

  async _handleMenuChange(e) {
    const { selectedItemId } = e.detail;
    this._filter = { skills: this._getSkillsForSkillCategory(selectedItemId)?.map(skill => skill.id) };
    await this._setupData(selectedItemId);
  }

  async _viewAllClicked(e) {
    const { href } = e.detail;
    this.navigate(href);
  }

  async _setupActivityCountBySkills() {
    const activityCountsBySkillSet = new Map();
    await Promise.all(this._skillProfileSubCategories.map(skillSubcategory => {
      const skillsFilter = new ActivityFilter({ skills: skillSubcategory?.skills.map(skill => skill?.id) });
      const filters = {
        from: 0,
        size: 70,
        randomizeOrder: false,
        filters: skillsFilter,
        property: undefined,
        sort: undefined,
      };
      return this.client.searchActivities(filters).then(response => {
        activityCountsBySkillSet.set(skillSubcategory?.skillCategoryId, response?.total?.value);
      }).catch(error => {
        console.error(`Fetching activities data failed for category: ${skillSubcategory.id}(${skillSubcategory.name})`, error);
      });
    }));

    this._activityCountsBySkillSet = activityCountsBySkillSet;
  }

  async _setupSkillSets() {
    if (this.careerTitle) {
      this._skillProfileSubCategories = await this.client.getSkillSubcategoriesForTitle(this.careerTitle.jobId, this.session.tenant.id ?? this.session.user.tenantId);
    } else {
      this.selectedSkillCategories?.forEach(selectedSubcategory => {
        selectedSubcategory.skills = this._getSkillsForSkillCategory(selectedSubcategory.skillCategoryId);
        this._skillProfileSubCategories.push(selectedSubcategory);
      });
    }
  }

  async _setupData(selectedSkillSetId = null) {
    if (this._skillProfileSubCategories.length === 0) return;

    if (this.careerTitle) {
      if (this._filter === null || selectedSkillSetId === null) {
        this._filter = { skills: this._skillProfileSubCategories.flatMap(
          skillSubcategory => skillSubcategory.skills
        ).map(skill => skill.id) };
      } else {
        this._filter = { skills: this._skillProfileSubCategories.find(
          skillSubCategory => skillSubCategory.skillCategoryId === selectedSkillSetId
        ).skills.map(skill => skill.id) };
      }
    } else {
      // For skillset carousels - sort all selected skillsets by activity count
      const sortedSkillCategories = this._skillProfileSubCategories.map(skillCategory => ({
        skillCategoryId: skillCategory.skillCategoryId,
        count: this._activityCountsBySkillSet.get(skillCategory.skillCategoryId),
      })).sort((a, b) => b.count - a.count);

      // The default selectedSkillSetId on load is the one with the highest activity count
      if (selectedSkillSetId === null) {
        selectedSkillSetId = sortedSkillCategories[0].skillCategoryId;
      }

      if (this._filter === null) {
        this._filter = {
          skills: this._getSkillsForSkillCategory(selectedSkillSetId)?.map(skill => skill.id),
        };
      }
    }

    let suffixId = selectedSkillSetId !== null ? `_${selectedSkillSetId}` : '';

    if (this.careerTitle) {
      suffixId = `_${this.careerTitle.jobId}${suffixId}`;
    }

    const bestResultsStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.bestResults}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.bestResults}${suffixId}`,
      filters: this._filter,
    });
    const microlearningStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.microlearning}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.microlearning}${suffixId}`,
      filters: this._filter,
      property: {
        name: 'tags',
        value: 'fasttocomplete',
      },
    });
    const coursesStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.courses}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.courses}${suffixId}`,
      filters: { certificateType: ['course'], type: ['course'], ...this._filter },
    });
    const shortCredentialsStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.shortCredentials}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.shortCredentials}${suffixId}`,
      filters: { certificateType: ['microcredential', 'certificate'], ...this._filter },
    });
    const degreesStream = new LandingStream({
      id: `${LANDING_STREAM_TYPES.degrees}/${suffixId}`,
      path: `${LANDING_STREAM_TYPES.interestedGoals}/${LANDING_STREAM_TYPES.degrees}${suffixId}`,
      filters: { certificateType: ['diploma', 'degree'], ...this._filter },
    });

    const interestedStreams = [bestResultsStream, microlearningStream, coursesStream, shortCredentialsStream, degreesStream].map(stream => {
      const queryParams = new URLSearchParams();
      queryParams.append('source', 'url');
      queryParams.append('filters', btoa(JSON.stringify(stream.filters)));
      stream.path = `${stream.path}?${queryParams.toString()}`;
      return stream;
    });

    const promises = interestedStreams.map(async stream => {
      const streamFilters = new ActivityFilter(stream.filters);
      return await this._callOpenSearch(stream, {
        from: 0,
        size: 70,
        filters: streamFilters,
        randomizeOrder: false,
        sort: stream.sort,
        property: stream.property,
      });
    });

    const streams = (await Promise.allSettled(promises)).map(({ status: streamStatus, value }) => ({ streamStatus, value }));
    this._streams = streams;

    const bestResults = this._getStreamData(streams[0]);

    const topThreeBestResults = bestResults.activities?.slice(0, 3);
    this._activityIdsToExclude = new Set(topThreeBestResults.map(activity => activity.id));

    const microlearning = this._getStreamData(streams[1]);
    const courses = this._getStreamData(streams[2]);
    const shortCredentials = this._getStreamData(streams[3]);
    const degrees = this._getStreamData(streams[4]);

    this._data = { bestResults, microlearning, courses, shortCredentials, degrees };
  }

}

window.customElements.define('skill-sets-carousel', SkillSetsCarousel);
