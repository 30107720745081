import { css, html, LitElement } from 'lit';

import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '../get-started/get-started.js';
import '../onboarding-personalization-flow/onboarding-personalization-flow.js';

import { bodyStandardStyles, heading1Styles, heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaAmplitudeMixin } from '../../../../shared/mixins/nova-amplitude-mixin/nova-amplitude-mixin.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';

export default class ViewOnboarding extends NovaAmplitudeMixin(LocalizeNova(RequesterMixin(nav(NovaNavMixin(LitElement))))) {

  static get properties() {
    return {
      _selectedJobTitleId: { type: String },
      _selectedJobTitleName: { type: String },
      _selectedJobTitleSOC: { type: String },
      _skillProfile: { type: Object },
      _generatingResults: { state: true },
      _step: { type: String },
      _animationSubtext: { type: Number },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      bodyStandardStyles,
      css`
        :host {
          display: block;
        }

        .loading-spinner-container {
          display: flex;
          height: 50vh;
        }

        .loading-spinner {
          margin: auto;
        }

        .lottie-animation {
          height: 70vh;
        }

        .animation-subtext {
          display: flex;
          justify-content: space-around;
        }

        .animation-subtext-secondary {
          color: var(--d2l-color-tungsten);
        }

        .subtext-header {
          margin-bottom: 18px;
          margin-top: 0;
        }
`,
    ];
  }

  constructor() {
    super();
    this._generatingResults = false;
    this._animationSubtext = this.localize('view-onboarding.generatingResults.subtext.GatherSkills');
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this._selectedJobTitleId = this.session?.user.getSetting('selectedTitleId');
    this._selectedJobTitleName = this.session?.user.getSetting('selectedTitleName');
    this._selectedJobTitleSOC = this.session?.user.getSetting('selectedTitleSOC');

    const { skillCategories, titles } = await this.client.getSkillProfile(this.session.user.tenantId, this.session.user.guid);

    this._skillProfile = {
      skillCategories: skillCategories,
      titles: titles,
    };

    const onboardingState = localStorage.getItem('onboarding-state');

    if (onboardingState && this._selectedJobTitleId) {
      this._step = onboardingState;
    } else {
      this._step = 'role';
    }
  }

  get getStartedTemplate() {
    return html`<get-started .jobTitleId=${this._selectedJobTitleId} .jobTitleName=${this._selectedJobTitleName} .jobTitleSOC=${this._selectedJobTitleSOC} @get-started-submitted=${this._getStartedSubmitted}></get-started>`;
  }

  _getStartedSubmitted(e) {
    localStorage.setItem('onboarding-state', 'goals');
    this._step = 'goals';
    this._selectedJobTitleId = e.detail.userSelectedJobTitleId;
    this._selectedJobTitleName = e.detail.userSelectedJobTitleName;
    this._selectedJobTitleSOC = e.detail.userSelectedJobTitleSOC;
  }

  get onboardingTemplate() {
    return html`
      <onboarding-personalization-flow
        .jobTitleId=${this._selectedJobTitleId}
        .jobTitleName=${this._selectedJobTitleName}
        .jobTitleSOC=${this._selectedJobTitleSOC}
        .selectionForProfile=${this._skillProfile}
        @go-to-get-started=${this.goToGetStarted}
        @go-to-generating-results=${this.goToGeneratingResults}>
      </onboarding-personalization-flow>
    `;
  }

  goToGetStarted(e) {
    localStorage.setItem('onboarding-state', 'role');
    this._step = 'role';
    this._skillProfile = e.detail;
  }

  async delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async setupAnimationTextTimers() {
    this._step = 'results';
    await this.delay(3000);
    this._animationSubtext = this.localize('view-onboarding.generatingResults.subtext.FingingCourses');
    await this.delay(2500);
    this._animationSubtext = this.localize('view-onboarding.generatingResults.subtext.AddingMagic');
    await this.delay(3000);
    this._animationSubtext = this.localize('view-onboarding.generatingResults.subtext.Enjoy');
    await this.delay(1500);
    const pathname = window.location.pathname;
    if (pathname.includes('onboarding')) {
      this.navigateWithoutHistory('/');
    }
  }

  async goToGeneratingResults(e) {
    this.setupAnimationTextTimers();

    const {
      selectedSkillCategories,
      selectedSkillIds,
      selectedTitles,
      recommendedTitles,
      recommendedSkillCategories,
    } = e.detail;

    const selectedTitleInformation = {
      selectedTitle: this._selectedJobTitleName,
      selectedTitleId: this._selectedJobTitleId,
      selectedTitleSoc: this._selectedJobTitleSOC,
    };

    if (this.session.needsOnboarding) {
      // fist time onboarding
      await this.client.saveSkillProfile({ skillIds: selectedSkillIds, skillCategories: selectedSkillCategories, titles: selectedTitles });

      this.logAmplitudeEvent('onboardingCompletedFirstTime', selectedTitleInformation);

    } else {
      const { skillCategories, skills } = await this.client.getSkillProfile(this.session.user.tenantId, this.session.user.guid);

      const profileSkillCategoryIds = skillCategories.map(cat => cat.skillCategoryId);
      const selectedSkillCategoryIds = selectedSkillCategories.map(cat => cat.skillCategoryId);

      const removedIds = profileSkillCategoryIds.filter(id => !selectedSkillCategoryIds.includes(id));
      const addedIds = selectedSkillCategoryIds.filter(id => !profileSkillCategoryIds.includes(id));

      const removedSkillCategories = skillCategories.filter(category => removedIds.includes(category.skillCategoryId));
      const addedSkillCategories = selectedSkillCategories.filter(category => addedIds.includes(category.skillCategoryId));

      const removedSkillIds = skills.filter(skill => removedIds.includes(skill?.skill?.subcategory.id)).map(skill => skill?.skillId);

      await this.client.updateSkillProfile(addedSkillCategories, removedSkillCategories, selectedSkillIds, removedSkillIds, selectedTitles);
    }

    this.session.user.flaggedTitles = selectedTitles.map(title => {
      return { jobId: title.id, jobName: title.name };
    });

    // update user settings
    if (this._selectedJobTitleId) {
      await this.client.setUserSetting('selectedTitleId', this._selectedJobTitleId);
    }
    if (this._selectedJobTitleName) {
      await this.client.setUserSetting('selectedTitleName', this._selectedJobTitleName);
    }
    if (this._selectedJobTitleSOC) {
      await this.client.setUserSetting('selectedTitleSOC', this._selectedJobTitleSOC);
    }
    await this.client.setUser(this.session.user);

    this.logAmplitudeEvent('onboardingCompletedSuggestedCareersSelected', {
      numberOfTitlesSelected: selectedTitles.length,
      selectedTitles,
      recommendedTitles,
      ...selectedTitleInformation,
    });

    this.logAmplitudeEvent('onboardingCompletedSuggestedSkillSetsSelected', {
      numberOfSkillSetsSelected: selectedSkillCategories.length,
      selectedSkillCategories,
      recommendedSkillCategories,
      ...selectedTitleInformation,
    });

    const selectedTitleChangedEvent = new CustomEvent('nova-selected-title-changed', {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(selectedTitleChangedEvent);
  }

  get _generatingResultsHtml() {
    return html`
      <nova-lottie-animation
        class="lottie-animation"
        animation-url="/assets/animation/onboarding-generating-results-animation.json"
        auto-play
        loop>
        <img slot="reduced-motion" height="100%" width="100%" role="presentation" src="/assets/img/onboarding-generating-results.svg"/>
      </nova-lottie-animation>
      <div class="animation-subtext d2l-heading-1 subtext-header">${this.localize('view-onboarding.generatingResults.text')}</div>
      <div class="animation-subtext d2l-body-standard animation-subtext-secondary">${this._animationSubtext}</div>
    `;
  }

  get _loadingSpinner() {
    return html`
      <div class="loading-spinner-container">
        <d2l-loading-spinner class="loading-spinner" size="50"></d2l-loading-spinner>
      </div>
    `;
  }

  render() {
    if (this._step === 'results') return this._generatingResultsHtml;
    if (this._step && this._step === 'goals') return this.onboardingTemplate;
    if (this._step && this._step === 'role') return this.getStartedTemplate;
    return this._loadingSpinner;
  }
}

window.customElements.define('view-onboarding', ViewOnboarding);
