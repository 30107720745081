import { css, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../mixins/nova-nav/nova-nav.js';

/**
 * This view should never really be visible. It's here to redirect to the correct deep link once the magic link is verified.
 */
export default class RedirectMagicLink extends LocalizeNova(RequesterMixin(NovaNavMixin(LitElement))) {

  static get properties() {
    return {
      params: { type: Object },
      query: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      heading3Styles,
      css`
        :host {
          display: block;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    // If there is a magic link, use magic link to populate session
    const urlParams = new URLSearchParams(window.location.search);
    const magic = urlParams.get('magic');
    try {
      if (magic) await this.session.verifyMagicLink(magic);
      await this.session.loadSession();
      const relayState = this._isValidRelayState(this.session.relayState) ? this.session.relayState : '/';
      this.navigateWithoutHistory(relayState);
    } catch (e) {
      this.navigateWithoutHistory('/login');
      const { code } = await e.json();
      this.session.toast({ type: 'error', message: this.localizeError(code), noAutoClose: true });
    }
  }

  render() {
    return nothing;
  }

  _isValidRelayState(relayState) {
    return relayState && !relayState.startsWith('/login/magic');
  }
}

window.customElements.define('redirect-magic-link', RedirectMagicLink);
