import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';

import '../../../../shared/components/general/registration-call-to-action/registration-call-to-action.js';
import '../../../components/landing/profile-card/profile-card.js';
import '../../../components/landing/skill-sets-carousel/skill-sets-carousel.js';
import '../../../../shared/components/general/take-action-today-carousel/take-action-today-carousel.js';

import ActivityFilter from '../../../../shared/models/activity-filter/activity-filter.js';

export default class ViewLandingPage extends LocalizeNova(RequesterMixin(nav(NovaNavMixin(LitElement)))) {

  static get properties() {
    return {
      _showRegCta: { state: true }, // show the registration call to action
      _regCtaActivityTitle: { type: String }, // set activity name for reg cta
      _regCtaAppUuid: { type: String }, // set uuid for reg cta
      _skillCategories: { type: Array }, // the selected skill sets
      _skills: { type: Array }, // related skills from the selected skill sets
      _titles: { type: Array }, // the selected careers
      _skillsSubCategories: { type: Array }, // the aggregated skills subcategories for each career title selected and the user's current role, plus the skill subcategories that the user has selected
      _activityBySkillsSubcategories: { type: Object }, // the activities for each deduped skill subcategory
      _availableCredit: { type: Object },
      _usedCredit: { type: Object },
      _profileSkeleton: { type: Boolean },
      _titlesIncludingUserRole: { type: Array },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
        :host {
          display: block;
          margin: 0 auto;
          max-width: 1170px;
          padding: 1.5rem 1.5rem 0 1.5rem;
          width: calc(100% - 3rem);
        }

        .landing-page-container {
          display: flex;
          flex-direction: column;
          row-gap: 48px;
        }

        .recommendations {
          display: flex;
          flex-direction: column;
          row-gap: 48px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._submitted = false;
    this._hasErrors = false;
    this._skillCategories = null;
    this._skills = null;
    this._titles = null;
    this._skillsSubCategories = null;
    this._activityBySkillsSubcategories = null;
    this._availableCredit = null;
    this._usedCredit = null;
    this._profileSkeleton = true;
    this._titlesIncludingUserRole = null;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    await this._setupSkillProfile();
    await this._setupUserCredit();
    await this._setupCareerTitleSkillSubCategories();
  }

  updated() {
    super.updated();
    if (this._skillCategories !== null && this._titles !== null && this._availableCredit !== null && this._usedCredit !== null) {
      this._profileSkeleton = false;
    }
  }

  firstUpdated() {
    if (this.session.needsOnboarding) {
      this.navigateWithoutHistory('/onboarding');
      return; // return because there is a chance stuff after this will still run before nav happens
    }
    this._prepRegistrationCallToAction();
  }

  async _prepRegistrationCallToAction() {
    const searchParams = { from: 0, size: 2, filters: { statuses:['approved'] } };
    const { applications } = await this.client.searchApplications(searchParams);
    const approvedApps = applications.hits;
    if (approvedApps?.length > 0) {
      this._showRegCta = true;
    }
    if (approvedApps?.length === 1) {
      const activityTitle = approvedApps[0].activity?.title;
      const appUuid = approvedApps[0].uuid;
      if (approvedApps && appUuid) { // both need to be available to show specific link to an app
        this._regCtaActivityTitle = activityTitle;
        this._regCtaAppUuid = appUuid;
      }
    }
  }

  get _registrationCtaTemplate() {
    return html`
      <registration-call-to-action
        .activityTitle=${this._regCtaActivityTitle}
        .applicationUuid=${this._regCtaAppUuid}>
      </registration-call-to-action>
    `;
  }

  render() {
    if (!this.session?.user.getSetting('selectedTitleId')) return nothing;
    return html`
      <div class="landing-page-container">
        <profile-card
          ?skeleton=${this._profileSkeleton}
          .skillCategories=${this._skillCategories}
          .titles=${this._titles}
          .availableCredit=${this._availableCredit}
          .usedCredit=${this._usedCredit}>
        </profile-card>
        <div class="recommendations">
          ${this._showRegCta ? this._registrationCtaTemplate : nothing }
          ${this._skillCategories !== null ? html`<skill-sets-carousel .selectedSkillCategories=${this._skillCategories} .activityBySkillsSubcategories=${this._activityBySkillsSubcategories} .skills=${this._skills} heading="${this.localize('view-landing-page.carousel.interestedSkillSet.heading')}"></skill-sets-carousel>` : nothing}
          <take-action-today-carousel></take-action-today-carousel>
          <skill-sets-carousel .skills=${this._skills} .activityBySkillsSubcategories=${this._activityBySkillsSubcategories} .careerTitle=${{ jobId: this.session?.user.getSetting('selectedTitleId'), jobName: this.session?.user.getSetting('selectedTitleName') }} heading="${this.localize('view-landing-page.carousel.userRole.heading')}"></skill-sets-carousel>
          ${this._titles !== null ? repeat(this._titles, title => title, title => (html`<skill-sets-carousel .skills=${this._skills} .activityBySkillsSubcategories=${this._activityBySkillsSubcategories} .careerTitle=${title} heading="${this.localize('view-landing-page.carousel.interestedCareer.heading')}"></skill-sets-carousel>`)) : nothing}
        </div>
      </div>
    `;
  }

  async _setupSkillProfile() {
    this._skillProfile = await this.client.getSkillProfile(this.session.tenant.id, this.session.user.guid);
    const { skillCategories, titles, skills } = this._skillProfile;
    this._skillCategories = skillCategories;
    this._titles = titles;
    this._titlesIncludingUserRole = [{ jobId: this.session?.user.getSetting('selectedTitleId'), jobName: this.session?.user.getSetting('selectedTitleName') }, ...this._titles];
    this._skills = skills;
  }

  async _setupUserCredit() {
    const { availableCredit } = await this.client.getAvailableCredit(this.session.user.guid);
    this._availableCredit = availableCredit;
    const { usedCredit } = await this.client.getUsedCredit(this.session.user.guid);
    this._usedCredit = usedCredit;
  }

  _getSkillsForSkillCategory(skillCategoryId) {
    if (this._skills === null) return [];
    const matchingSkills = this._skills.filter(skill => skillCategoryId === skill?.skill?.subcategory.id);
    return matchingSkills?.map(matchingSkill => matchingSkill.skill);
  }

  async _setupCareerTitleSkillSubCategories() {
    const titleSkillSetData = await Promise.all(this._titlesIncludingUserRole.map(async title => {
      const skillSubCategories = await this.client.getSkillSubcategoriesForTitle(title?.jobId, this.session.tenant.id ?? this.session.user.tenantId);
      return skillSubCategories;
    }));

    this._skillCategories = this._skillCategories?.map(subCat => {
      subCat.skills = this._getSkillsForSkillCategory(subCat.skillCategoryId);
      return subCat;
    });

    const allSubCategories = [this._skillCategories, ...titleSkillSetData].flat();
    const uniqueCategoriesIds = new Set(allSubCategories.map(cat => cat.skillCategoryId));

    this._skillsSubCategories = [...uniqueCategoriesIds].map(id => {
      const subcategory = allSubCategories.filter(cat => cat.skillCategoryId === id);
      return subcategory[0];
    });

    const activityBySkillsSubcategories = {};

    await Promise.all(this._skillsSubCategories.map(async category => {
      const skillsFilter = new ActivityFilter({ skills: category.skills.map(skill => skill.id) });
      const filters = {
        from: 0,
        size: 70,
        randomizeOrder: false,
        filters: skillsFilter,
        property: undefined,
        sort: undefined,
      };
      return this.client.searchActivities(filters).then(activities => {
        activityBySkillsSubcategories[category?.skillCategoryId] = {
          ...category,
          activities: activities?.hits,
          count: activities?.total.value,
        };
      })
        .catch(error => {
          console.error(`Fetching activities for skill category ${category.skillCategoryId}(${category.skillCategoryName}) failed`, error);
        });
    }));

    this._activityBySkillsSubcategories = activityBySkillsSubcategories;
  }

  /** Helper function to dedupe the SkillSubcategories */
  _dedupeBySkillCategoryId(data) {
    const categoryMap = new Map();

    data.flat().forEach(entry => {
      const categoryId = entry.skillCategoryId;

      if (categoryMap.has(categoryId)) {
        const existingCategory = categoryMap.get(categoryId);

        // Combine skills and ensure no duplicates
        const combinedSkills = [...existingCategory.skills, ...entry.skills];
        const uniqueSkills = Array.from(
          new Map(combinedSkills.map(skill => [skill.id, skill])).values()
        );

        // Update total hype
        existingCategory.skills = uniqueSkills;
        existingCategory.totalHype = uniqueSkills.reduce(
          (sum, skill) => sum + skill.hype,
          0
        );
      } else {
        // Add new category entry
        categoryMap.set(categoryId, { entry });
      }
    });

    return Array.from(categoryMap.values());
  }

  // TODO (DONE): Call OS to get the activities hits by each skillSubcategory's skills' ID. Look at this method _setupActivityCountBySkills in <skill-sets-carousel> for reference. Build the data into the return value and pass to the skill-sets-carousel component.
  /** Helper function to get all the activities from OS based on the skills from deduped skillCategories */
  async _setupActivityByDedupedSkillsSubcategories() {
    const activityByDedupedSkillsSubcategories = new Map();
    await Promise.all(this._skillsSubCategories.map(async category => {
      const skillsFilter = new ActivityFilter({ skills: category.skills.map(skill => skill.id) });
      const filters = {
        from: 0,
        size: 70,
        randomizeOrder: false,
        filters: skillsFilter,
        property: undefined,
        sort: undefined,
      };
      return this.client.searchActivities(filters).then(activities => {
        activityByDedupedSkillsSubcategories.set(category?.skillCategoryId, {
          ...category,
          activities: activities?.hits,
          count: activities?.total.value,
        });
      })
        .catch(error => {
          console.error(`Fetching activities for skill category ${category.skillCategoryId}(${category.skillCategoryName}) failed`, error);
        });
    }));

    this._activityBySkillsSubcategories = activityByDedupedSkillsSubcategories;
  }
}

window.customElements.define('view-landing-page', ViewLandingPage);
